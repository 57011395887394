import React from "react";
import { UiImage, UiText, UiView, UiLink } from "@dex/bubl-ui";

import styles from "./Banner.module.scss";
import Container from "../Container/Container";

const Banner: React.FC<BannerProps> = (props) => {

    console.log(props.avatar);

    return (

        <UiView className={styles.banner}>

            <Container>

                <UiView
                    className={styles.center}
                    background={{
                        src: props.backgroundImage,
                        type: "thumb",
                        resizeMode: "cover",
                        filters: { width: Math.min(window.innerWidth, 1400), height: 300 }
                    }}
                >

                    <UiImage
                        className={styles.avatar}
                        resizeMode={'center'}
                        filters={{ width: 200, height: 200, textSize: 96 }}
                        {...props.avatar}
                    />

                    <UiView className={styles.headings}>

                        <UiText className={styles.subHeading}>
                            {props.subHeading}
                        </UiText>

                        {props.heading &&

                            <UiText className={styles.heading}>
                                {props.heading}
                            </UiText>

                        }

                        {props.badgeText &&

                            <UiText className={styles.badge}>
                                {props.badgeText}
                            </UiText>

                        }

                        {props.point &&

                            <UiText className={styles.point}>
                                {props.point}

                                <UiText className={styles.smallText}>Huddle Points</UiText>

                            </UiText>

                        }

                    </UiView>

                    {props.rightCornerBtn &&

                        <UiLink className={styles.btnCorner}>{props.rightCornerBtn}</UiLink>

                    }

                </UiView>

            </Container>

        </UiView>

    );
};

interface BannerProps {
    heading?: string;
    subHeading?: string;
    [key: string]: any;
}

export default Banner;
