import React from "react";
import { UiImage, UiLink, UiText, UiView } from "@dex/bubl-ui";
// @ts-ignore
import { StickyTable, Row, Cell } from 'react-sticky-table';

import styles from "./StatsRosterTable.module.scss";

const StatsRosterTable: React.FC<StatsRosterTableProps> = (props) => {

    const { stats, columns, seasonId, seasonSlug } = props;

    if (!stats) return <></>;

    const header: any = [];

    header.push({
        key: 'number',
        label: '#',
        type: 'text',
    });

    header.push({
        key: 'name',
        label: 'PLAYER',
        type: 'text',
    });

    header.push({
        key: 'position',
        label: 'position',
        type: 'text',
    });

    columns.map((column: any) => {

        if (column.stat) header.push(column);

        return column;

    })

    return (

        <UiView>

            <StickyTable
                className={styles.table}
                stickyHeaderCount={1}
                leftStickyColumnCount={2}
                borderWidth={0}>

                <Row className="head">

                    {header.map((item: any, index: number) => (

                        <Cell key={index} className={item.key}>{item.label}</Cell>

                    ))}

                </Row>

                {stats &&
                    stats.map((item: any, index: any) => (

                        <Row key={index}>

                            {header.map((key: any, cellIndex: any) => (

                                <Cell key={cellIndex} className={key.key}>
                                    <>

                                        {key.key !== 'name' && key.key !== 'position' && item[key.key]}

                                        {key.key === 'name' &&
                                            <UiLink path={`/leagues/season/${seasonId}/${seasonSlug}/player/${item.id}/${item.slug}`} className={styles.player}>

                                                <UiImage
                                                    src={item.photo || { fileId: "-" }}
                                                    type={"thumb"}
                                                    resizeMode="cover"
                                                    className={styles.playerImage}
                                                    filters={{ width: 100, height: 100, fallback: { text: item.name[0] } }}
                                                />

                                                <UiText>{item[key.key]}</UiText>

                                            </UiLink>
                                        }

                                        {key.key === 'number' &&
                                            <UiText>{item['jerseyNumber']}</UiText>
                                        }

                                        {key.key === 'position' &&
                                            <UiText>{item[key.key]}</UiText>
                                        }

                                        {(item.stats && key.stat) &&
                                            <>
                                                {item?.stats?.totals[key.key] || "0"}

                                                {/* {key.key === 'fgm,fga' && item?.stats?.totals.fgm + " - " + item?.stats?.totals.fga}

                                                {key.key === 'threepm,threepa' && item?.stats?.totals.threepm + " - " + item?.stats?.totals.threepa}

                                                {key.key === 'ftm,fta' && item?.stats?.totals.ftm + " - " + item?.stats?.totals.fta} */}
                                            </>
                                        }

                                    </>

                                </Cell>

                            ))}

                        </Row>

                    ))
                }


            </StickyTable>

        </UiView >

    );
};

interface StatsRosterTableProps {
    [key: string]: any;
}

export default StatsRosterTable;
